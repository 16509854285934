import React from 'react';

import { Box, Avatar, Typography } from '@material-ui/core';

export function BotMenuItem(props) {

  return (
    <Box display='flex' alignItems='center'>
      <Avatar src={props.item.imagenAvatar} style={{ marginRight: '15px' }} />
      <Box>
        <Typography variant='body1'>{props.item.nombre}</Typography>
        <Typography variant='body2'>{props.item.descripcion}</Typography>
      </Box>
    </Box>
  );
};