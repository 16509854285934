import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import { Button, Avatar, IconButton  } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';


import { Container, TextField } from '@material-ui/core';

// utilidades
import { ConfirmDialog } from '../../component/utils';



export function EmpresaViewdEdit(props) {

  // inicializamos el hook con el estado
  const [state, setState] = useState(props.item);


  // pantalla de confirmacion
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  // ref para el input de tipo file
  const hiddenFileInput = React.createRef();


  // función que controla los cambios en los textfield
  const handleInputChange = e => {
    const { name, value } = e.target
    setState({ ...state, [name]: value })
  }

  // comprobamos si hemos cambiado de registro para mostrar
  useEffect(() => {
    setState(props.item);
  }, [props.item]);


  return (
    <Container maxWidth='md' className='background-forms'>

      <ConfirmDialog
        content="¿Borrar registro?"
        open={openConfirmationDialog}
        setOpen={(value) => setOpenConfirmationDialog(value)}
        onConfirm={() => props.onDelete(state)}
      />

      <Typography align="center" variant="h5" style={{ margin: '10px' }}>{'Empresa'}</Typography>

      <Box>

        <Box>
          <input ref={hiddenFileInput} accept='image/*' type='file' style={{ display: 'none' }}
            onChange={e => {
              setState({
                ...state,
                imagenData: e.target.files[0],
                imagenAvatar: URL.createObjectURL(e.target.files[0])
              });
            }} />
          <Avatar style={{ marginRight: '10px' }}>
            <IconButton onClick={() => { hiddenFileInput.current.click(); }} >
              <PhotoCameraIcon />
            </IconButton>
          </Avatar>

          <Box display='flex' justifyContent='center' width={1}>
            <img
              alt={state.nombre}
              src={state.imagenAvatar}
              style={{ margin: '10px', maxWidth: '100%', maxHeight: '200px' }}
            />
          </Box>

        </Box>

        <TextField
          margin='dense'
          size='small'
          variant='standard'
          fullWidth
          id="nombre"
          label='Empresa'
          name='nombre'
          autoComplete='nombre'

          value={state.nombre}
          onChange={handleInputChange}
        />

        <TextField
          margin='dense'
          size='small'
          variant='standard'
          fullWidth
          id="direccion"
          label='Dirección'
          name='direccion'
          autoComplete='direccion'

          value={state.direccion}
          onChange={handleInputChange}
        />

        <TextField
          margin='dense'
          size='small'
          variant='standard'
          fullWidth
          id="email"
          label='Email'
          name='email'
          autoComplete='email'

          value={state.email}
          onChange={handleInputChange}
        />

        <TextField
          margin='dense'
          size='small'
          variant='standard'
          fullWidth
          id="cif"
          label='CIF/NIF'
          name='cif'
          autoComplete='cif'

          value={state.cif}
          onChange={handleInputChange}
        />

        <TextField
          margin='dense'
          size='small'
          variant='standard'
          fullWidth
          id="telefono"
          label='Teléfono'
          name='telefono'
          autoComplete='telefono'

          value={state.telefono}
          onChange={handleInputChange}
        />

      </Box>

      <div style={{ height: 20 }}></div>

      <Box display='flex' justifyContent='flex-end' width={1}>

        {
          props.onDelete ?
            <Button
              variant="contained"
              color="secondary"
              style={{ margin: '5px' }}
              onClick={() => setOpenConfirmationDialog(true)}
              disabled={true}
            >
              Borrar
            </Button>
            : <div></div>
        }

        <Button
          variant="contained"
          color="default"
          style={{ margin: '5px' }}
          onClick={() => props.cancel()}
        >
          Cancelar
        </Button>

        <Button
          variant="contained"
          color="primary"
          style={{ margin: '5px' }}
          onClick={() => {
            props.save(state);
            props.cancel();
          }}

        >
          Guardar
        </Button>
      </Box>

    </Container >

  );
};