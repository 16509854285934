import React from 'react';

import { Typography, Grow, Chip, Box, Avatar, TextField, IconButton } from '@material-ui/core/';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import { AddCircle, Search } from '@material-ui/icons';

class ShowTableData extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            rowsPerPage: 10,
            page: 0,
            emptyRows: 1,

            headers: this.props.headers ? this.props.headers : [],

            ordenar: [],
            filtros: [],
            onClick: this.props.onClick ? this.props.onClick : () => { },
        }

        this.onChangeInput = this.onChangeInput.bind(this);
        this.onOrdenar = this.onOrdenar.bind(this);
        this.onFiltrar = this.onFiltrar.bind(this);

        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data) {
            this.setState({ page: 0 });
        }
    }

    handleChangePage(newPage) {
        this.setState({ page: newPage });
    };

    handleChangeRowsPerPage(event) {
        this.setState({ rowsPerPage: parseInt(event.target.value), page: 0 });
    };

    onChangeInput(event) {
        let tmpFiltros = [...this.state.filtros];

        if (tmpFiltros.filter(x => x.campo === event.target.name).length === 0) {
            tmpFiltros.push({ campo: event.target.name, valor: event.target.value });
        }
        else {
            tmpFiltros.forEach((element, index) => {
                if (element.campo === event.target.name) tmpFiltros[index].valor = event.target.value;

                if (!event.target.value) {
                    tmpFiltros.splice(index, 1);
                }
            });
        }

        this.setState({ filtros: tmpFiltros });
    }

    onOrdenar(por) {

        if (por) {

            let tmpOrdenar = this.state.ordenar;
            if (!tmpOrdenar[por]) {
                tmpOrdenar[por] = 'asc';
            }
            else {
                tmpOrdenar[por] = tmpOrdenar[por] === 'asc' ? 'desc' : 'asc';
            }

            let datosOrdenados = this.props.data ? this.props.data : [];

            // si quieremos ordenar la fecha de vento, entonces se hace un tratamiento especial, ya que es una fecha
            tmpOrdenar[por] === 'asc' ?
                datosOrdenados.sort((a, b) => (a[por] > b[por]) ? 1 : ((b[por] > a[por]) ? -1 : 0))
                : datosOrdenados.sort((a, b) => (a[por] < b[por]) ? 1 : ((b[por] < a[por]) ? -1 : 0));

            this.setState({ data: datosOrdenados, ordenar: tmpOrdenar });
        }
    }

    renderData(data, tipo) {

        if (tipo === 'array') {
            return data.map((element) => {
                return <Chip
                    label={element}
                    size='small'
                    color="primary"
                    style={{ margin: '3px' }}
                    key={element}
                />
            })
        }

        if (tipo === 'imagen') {
            return <Box display='flex' justifyContent='center'><Avatar src={data} /></Box>
        }

        if (tipo === 'numero') {
            return <Box display='flex' justifyContent='flex-end'>{data}</Box>
        }

        if (tipo === 'bool') {
            return <Box display='flex' justifyContent='center'>{data && data === true ? 'true' : 'false'}</Box>
        }

        return data;
    }

    onFiltrar() {

        //this.props.data.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)

        let filtrado = [...this.props.data];
        this.state.filtros.forEach((element) => {
            filtrado = filtrado.filter(x => x[element.campo] && x[element.campo].toString().toLowerCase().indexOf(element.valor.toString().toLowerCase()) >= 0);
        });

        return filtrado;
    }

    render() {

        if (!this.props.headers || this.props.headers.length === 0) {
            return <div></div>;
        }

        return (
            <Box>

                <div style={{ height: 10 }}></div>

                {
                    this.props.onNuevo != undefined
                        ? <IconButton onClick={this.props.onNuevo}>
                            <AddCircle />
                        </IconButton>

                        : <div></div>
                }

                <TableContainer className='background-list-cards'>
                    <Table size='small'>
                        <TableHead style={{ backgroundColor: '#2a5979' }}>
                            <TableRow>
                                {
                                    this.props.headers.map((element) => {
                                        return <TableCell key={element.nombre}>
                                            <TableSortLabel
                                                direction={this.state.ordenar[element.nombre] ? this.state.ordenar[element.nombre] : 'asc'}
                                                onClick={() => this.onOrdenar(element.nombre)}>
                                                {element.caption}
                                            </TableSortLabel>
                                        </TableCell>

                                    })
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                {
                                    this.props.headers.map((element, index) => {
                                        return <TableCell key={element.nombre}>
                                            {element.buscar ?
                                                <TextField
                                                    margin='dense'
                                                    size='small'
                                                    variant='standard'
                                                    fullWidth
                                                    id={element.nombre + index}
                                                    label={<Box display='flex'><Search /><Typography>{element.caption}</Typography></Box>}
                                                    name={element.nombre}

                                                    value={this.state.filtros.filter(x => x.campo === element.nombre).length > 0 ? this.state.filtros.filter(x => x.campo === element.nombre)[0].valor : ''}
                                                    onChange={this.onChangeInput}
                                                />
                                                : <div></div>
                                            }

                                        </TableCell>

                                    })
                                }
                            </TableRow>


                            {(this.onFiltrar().slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                            ).map((element, index) => (
                                <Grow in={true} timeout={index < 50 ? 100 * index : 0} key={'table_' + index} >
                                    <TableRow hover onClick={() => this.state.onClick(element)} key={index + '_tablerow_'}>
                                        {this.props.headers.map((header) => {
                                            return <TableCell key={'tablecell_' + index + '_' + header.nombre}>
                                                <Box>
                                                    {this.renderData(element[header.nombre], header.tipo)}
                                                </Box>
                                            </TableCell>

                                        })}
                                    </TableRow>
                                </Grow>
                            ))}

                            {this.state.emptyRows > 0 && (
                                <TableRow style={{ height: 53 * this.state.emptyRows }}>
                                    <TableCell colSpan={this.props.headers.length} />
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[10, 20, 50, { label: 'All', value: -1 }]}
                                    colSpan={7}
                                    count={this.onFiltrar().length}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.page}
                                    onChangePage={(e, page) => this.handleChangePage(page)}
                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Box>
        );
    }
}

export default ShowTableData;