import React from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import Header from '../../component/header';

import { Usuarios } from '../../entity/usuarios';

class EditUser extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      nombre: JSON.parse(localStorage.getItem('userLogged')).nombre,
      email: JSON.parse(localStorage.getItem('userLogged')).email,
      errorLogin: false,
      errorText: ''
    }


    this.onGuardar = this.onGuardar.bind(this);
    this.onBack = this.onBack.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
  }

  onGuardar(event) {
    event.preventDefault();

    this.setState({ loading: true, errorLogin: false });

    let formulario = {
      nombre: this.state.nombre,
      email: this.state.email,
    }

    Usuarios.guardar(formulario)
      .then(
        user => {
          if (user === true) {
            // actualizo los datos en memoria
            let userLogged = JSON.parse(localStorage.getItem('userLogged'));
            userLogged.nombre = this.state.nombre;
            userLogged.email = this.state.email;

            localStorage.setItem('userLogged', JSON.stringify(userLogged));


            const { from } = this.props.location.state || { from: { pathname: "/main" } };
            this.props.history.push(from);
          }
          else {
            this.setState({ errorText: user.message, errorLogin: true, loading: false });
          }
        }
      );

  }

  onBack() {
    window.history.back()
  }

  onChangeInput(event) {
    this.setState({ [event.target.id]: event.target.value });
  }

  render() {
    const AlertloginFailed = <Alert severity="error">{this.state.errorText}</Alert>;

    return (
      <div className="backgroundJuego">

        <Header title="Inicio" history={this.props.history}></Header>

        <div style={{ height: 20 }}></div>

        <Container maxWidth="xs">

          <Box className='background-forms'>

            <Typography align="center" variant="h5">Edición del Perfil</Typography>

            <TextField
              margin="normal"
              required
              fullWidth
              id="nombre"
              label="Nombre"
              name="nombre"
              autoComplete="nombre"
              defaultValue={this.state.nombre}
              autoFocus

              onChange={this.onChangeInput}
            />

            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              defaultValue={this.state.email}

              onChange={this.onChangeInput}
            />

            <div style={{ height: 20 }}></div>

            <Box display="flex" justifyContent="center">

              <Button
                fullWidth
                style={{ margin: 5 }}
                variant="contained"
                disabled={this.state.loading}
                color="primary"
                onClick={this.onGuardar}
              >
                Guardar
          </Button>

              <Button
                fullWidth
                style={{ margin: 5 }}
                variant="contained"
                disabled={this.state.loading}
                color="primary"
                onClick={this.onBack}
              >
                Volver
          </Button>

            </Box>

            {this.state.errorLogin === true ? AlertloginFailed : ''}

          </Box>


        </Container>
      </div>
    );
  }
}

export default EditUser;