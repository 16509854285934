import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grow from '@material-ui/core/Grow';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';

import AttachMoneyIcon from '@material-ui/icons/AttachMoney';


export function SimulacionViewWorld(props) {

  return (
    <Box className='background-list-cards' m={1} onClick={() => { props.setElementShow(props.item) }} >

      <Typography align="center" variant="h6">{props.item.segmentoNombre}</Typography>

      <Box display='flex'>

        <Grow in={true}>
          <Box display='flex' justifyContent='center' flexDirection="column" height='100px' marginRight='10px'>
            <img
              alt={props.item.nombre}
              src={props.item.imagenAvatar}
              style={{ maxWidth: '300px', maxHeight: '100px' }}
            />

            <Box m={1}>
              <Typography align="center" variant="h4">{props.item.empresaNombre}</Typography>
            </Box>

          </Box>
        </Grow>

        <Box>

          <Box m={1}>
            <Typography align="center" variant="body1">{props.item.nombre}</Typography>
          </Box>

          <Box m={1}>
            <Typography align="justify" variant="caption">{props.item.descripcion}</Typography>
          </Box>

          <Box m={1}>
            <Typography align="justify" variant="caption">{props.item.objetivo}</Typography>
          </Box>

          <Box display='flex' justifyContent='center' m={1}>
            <Chip
              avatar={<Avatar><AttachMoneyIcon /></Avatar>}
              label={props.item.presupuesto}
              clickable
              size='small'
              color="primary"
              style={{ margin: '3px', padding: '10px' }}
            />
          </Box>


        </Box>

      </Box>


    </Box >
  );
};