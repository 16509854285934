import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import { Container, IconButton, TextField, Typography, Slider } from '@material-ui/core';
import { FormControlLabel, Checkbox, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import DeleteIcon from '@material-ui/icons/Delete';

import LinearProgress from '@material-ui/core/LinearProgress';

import { ContainerSkills } from '../../component/containerSkills';

// utilidades
import { ConfirmDialog } from '../../component/utils';


export function ItemViewWorldEdit(props) {

  // inicializamos el hook con el estado
  const [state, setState] = useState(props.item);

  // pantalla de confirmacion
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);


  // ref para el input de tipo file
  const hiddenFileInput = React.createRef();

  // función que controla los cambios en los textfield
  const handleInputChange = e => {
    const { name, value } = e.target
    setState({ ...state, [name]: value })
  }

  // comprobamos si hemos cambiado de registro para mostrar
  useEffect(() => {
    setState(props.item);
  }, [props.item]);

  useEffect(() => {
  }, [state.imagenAvatar]);

  // esta función añade un nuevo skill al item
  const addSkill = element => {
    let tmp = state.skills;

    // si el skill ya está añadido, no lo vuelvo a añadir
    let existe = tmp.filter(x => x.id === element.id).length;

    // si no existe, lo añado
    if (existe === 0) {
      tmp.push(element);
    }

    setState({ ...state, 'skills': tmp });
  }

  return (
    <Container maxWidth='sm'>

      <ConfirmDialog
        content="¿Borrar registro?"
        open={openConfirmationDialog}
        setOpen={(value) => setOpenConfirmationDialog(value)}
        onConfirm={() => props.onDelete(state)}
      />

      <Box display='flex' alignItems='flex-start'>

        <Box className='background-forms'>

          <Box>
            <Box display='flex' justifyContent="center">
              <img
                alt={state.nombre}
                src={state.imagenAvatar}
                style={{ margin: '10px', maxWidth: '100%', maxHeight: '200px' }}
              />
            </Box>
            <Box display='flex'>

              <input ref={hiddenFileInput} accept='image/*' type='file' style={{ display: 'none' }}
                onChange={e => {
                  setState({
                    ...state,
                    imagenData: e.target.files[0],
                    imagenAvatar: URL.createObjectURL(e.target.files[0])
                  });
                }} />
              <Avatar style={{ marginRight: '10px' }}>
                <IconButton onClick={() => { hiddenFileInput.current.click(); }} >
                  <PhotoCameraIcon />
                </IconButton>
              </Avatar>

              <TextField
                margin='dense'
                size='small'
                variant='standard'
                fullWidth
                id="nombre"
                label='Nombre'
                name='nombre'
                autoComplete='nombre'

                value={state.nombre}
                onChange={handleInputChange}
              />

            </Box>

            <TextField
              margin='dense'
              size='small'
              variant='standard'
              fullWidth
              id="coste"
              label='Coste'
              name='coste'
              type='number'

              value={state.coste}
              onChange={handleInputChange}
            />

            <TextField
              margin='dense'
              size='small'
              variant='standard'
              fullWidth
              id="usos"
              label='Usos (0 infinito)'
              name='usos'
              type='number'

              value={state.usos}
              onChange={handleInputChange}
            />

            <TextField
              margin='dense'
              size='small'
              variant='standard'
              fullWidth
              id="usos"
              label='Nivel'
              name='nivel'
              type='number'

              value={state.nivel}
              onChange={handleInputChange}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={state.aInventario}
                  onChange={(e) => setState({ ...state, 'aInventario': e.target.checked })}
                  name={"aInventario"}
                  color="primary"
                />
              }
              label="A Inventario"
            />

            <FormControl fullWidth>
              <InputLabel id="set-simulacion">Simulacion a Ejecutar</InputLabel>
              <Select
                labelId="set-simulacion"
                id="iniciarSimulacion"
                value={state.iniciarSimulacion}
                onChange={(e) => setState({ ...state, 'iniciarSimulacion': e.target.value })}
              >
                {
                  props.simulaciones.map((element) => {
                    return <MenuItem value={element._id} key={element._id}>{element.nombre}</MenuItem>
                  })
                }
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel id="set-empresa">Empresa</InputLabel>
              <Select
                labelId="set-empresa"
                id="empresa"
                value={state.empresaTarget}
                onChange={(e) => setState({ ...state, 'empresaTarget': e.target.value })}
              >
                {
                  props.empresas.map((element) => {
                    return <MenuItem value={element._id} key={element._id}>{element.nombre}</MenuItem>
                  })
                }
              </Select>
            </FormControl>


            <div style={{ height: 20 }}></div>

            <Box display='flex' justifyContent='flex-end' width={1}>

              {
                props.onDelete ?
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{ margin: '5px' }}
                    onClick={() => setOpenConfirmationDialog(true)}
                  >
                    Borrar
                  </Button>
                  : <div></div>
              }

              <Button
                variant="contained"
                color="default"
                style={{ margin: '5px' }}
                onClick={() => props.cancel()}
              >
                Cancelar
              </Button>

              <Button
                variant="contained"
                color="primary"
                style={{ margin: '5px' }}
                onClick={() => props.save(state)}
              >
                Guardar
              </Button>
            </Box>

          </Box>

          {
            state.skills && state.skills.length > 0
              ? (
                <Box className='background-list-cards' m={1} p={1}>
                  {
                    state.skills.map((element, index) => {
                      return <Box m={1} key={element.id + element.nombre} display='flex' alignItems='center'>

                        <Avatar
                          src={element.imagenAvatar}
                          alt={element.nombre}
                          title={element.nombre} />

                        <TextField
                          size='small'
                          variant='standard'
                          fullWidth
                          id="nivel"
                          label={element.nombre}
                          name='nivel'
                          type='number'
                          style={{ margin: 10 }}

                          value={element.valor}
                          onChange={(event) => {
                            let tmpSkill = state.skills;
                            tmpSkill[index].valor = event.target.value;
                            setState({ ...state, 'skills': tmpSkill });
                          }}
                        />

                        <IconButton onClick={() => {
                          let tmpSkill = state.skills;
                          tmpSkill.splice(index, 1);
                          setState({ ...state, 'skills': tmpSkill });
                        }} >
                          <DeleteIcon />
                        </IconButton>

                      </Box>
                    }
                    )
                  }
                </Box>
              )
              : ''
          }


        </Box>

        <ContainerSkills skills={props.skills} addSkill={addSkill} />

      </Box>
    </Container>

  );
};