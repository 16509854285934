import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import {Button, FormControl, InputLabel, Select, MenuItem} from '@material-ui/core';

import { Container, IconButton, TextField } from '@material-ui/core';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';

// utilidades
import { ConfirmDialog } from '../../component/utils';


export function SkillViewWorldEdit(props) {

  // inicializamos el hook con el estado
  const [state, setState] = useState(props.item);

  // pantalla de confirmacion
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);


  // ref para el input de tipo file
  const hiddenFileInput = React.createRef();

  // función que controla los cambios en los textfield
  const handleInputChange = e => {
    const { name, value } = e.target
    setState({ ...state, [name]: value })
  }

  // comprobamos si hemos cambiado de registro para mostrar
  useEffect(() => {
    setState(props.item);
  }, [props.item]);

  useEffect(() => {
  }, [state.imagenAvatar]);

  return (
    <Container maxWidth='xs'>

      <ConfirmDialog
        content="¿Borrar registro?"
        open={openConfirmationDialog}
        setOpen={(value) => setOpenConfirmationDialog(value)}
        onConfirm={() => props.onDelete(state)}
      />

      <Box className='background-forms'>

        <Box display='flex' justifyContent="center">
          <img
            alt={state.nombre}
            src={state.imagenAvatar}
            style={{ margin: '10px', maxWidth: '100%', maxHeight: '200px' }}
          />
        </Box>

        <Box display='flex'>

          <input ref={hiddenFileInput} accept='image/*' type='file' style={{ display: 'none' }}
            onChange={e => {
              setState({
                ...state,
                imagenData: e.target.files[0],
                imagenAvatar: URL.createObjectURL(e.target.files[0])
              });
            }} />
          <Avatar style={{ marginRight: '10px' }}>
            <IconButton onClick={() => { hiddenFileInput.current.click(); }} >
              <PhotoCameraIcon />
            </IconButton>
          </Avatar>

          <TextField
            margin='dense'
            size='small'
            variant='standard'
            fullWidth
            id="nombre"
            label='Nombre'
            name='nombre'
            autoComplete='nombre'

            value={state.nombre}
            onChange={handleInputChange}
          />

        </Box>

        <Box display='flex' flexDirection='column'>

          <TextField
            margin='dense'
            size='small'
            variant='standard'
            id="minimo"
            label='Minimo'
            name='minimo'
            autoComplete='minimo'
            type='number'
            InputProps={{ inputProps: { min: 0 } }}

            value={state.minimo}
            onChange={handleInputChange}
          />

          <TextField
            margin='dense'
            size='small'
            variant='standard'
            id="maximo"
            label='Maximo'
            name='maximo'
            autoComplete='maximo'
            type='number'
            InputProps={{ inputProps: { min: 0 } }}

            value={state.maximo}
            onChange={handleInputChange}
          />

          <TextField
            margin='dense'
            size='small'
            variant='standard'
            id="valor"
            label='Valor'
            name='valor'
            autoComplete='valor'
            type='number'
            InputProps={{ inputProps: { min: 0, max: state.maximo } }}

            value={state.valor}
            onChange={handleInputChange}
          />

          <FormControl fullWidth>
            <InputLabel id="set-empresa">Empresa</InputLabel>
            <Select
              labelId="set-empresa"
              id="empresa"
              value={state.empresaTarget}
              onChange={(e) => setState({ ...state, 'empresaTarget': e.target.value })}
            >
              {
                props.empresas.map((element) => {
                  return <MenuItem value={element._id} key={element._id}>{element.nombre}</MenuItem>
                })
              }
            </Select>
          </FormControl>


        </Box>

        <div style={{ height: 20 }}></div>

        <Box display='flex' justifyContent='flex-end' width={1}>

          {
            props.onDelete ?
              <Button
                variant="contained"
                color="secondary"
                style={{ margin: '5px' }}
                onClick={() => setOpenConfirmationDialog(true)}
              >
                Borrar
              </Button>
              : <div></div>
          }

          <Button
            variant="contained"
            color="default"
            style={{ margin: '5px' }}
            onClick={() => props.cancel()}
          >
            Cancelar
          </Button>

          <Button
            variant="contained"
            color="primary"
            style={{ margin: '5px' }}
            onClick={() => props.save(state)}
          >
            Guardar
          </Button>
        </Box>

      </Box>

    </Container>

  );
};