import { Constantes } from '../util/constantes';


export const Usuarios = {
    login,
    registro,
    logout,

    guardar,
    validaToken,

    getAll,
};



// funcion para obtener datos
async function getGeneral(URI) {

    const requestOptions = {
        method: 'GET',
        headers: Constantes.getAuthHeaders()
    };

    const response = await fetch(`${Constantes.API_URL}${URI}`, requestOptions)
        .then((data) => {

            if (data) {
                return data.json()
            }
            else {
                return false;
            }

        })
        .then((info) => {
            return info;
        })
        .catch((error) => {
            return false;
        });

    return response;
}





async function login(usuario, clave) {

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        redirect: 'follow',

        body: JSON.stringify({
            usuario: usuario,
            clave: clave,
        }),
    };

    const response = await fetch(`${Constantes.API_URL}login`, requestOptions)
        .then((data) => {

            if (data) {
                return data.json()
            }
            else {
                return false;
            }

        })
        .then((info) => {

            if (info.records) {
                localStorage.setItem('userLogged', JSON.stringify(info.records));
            }
            return info;
        })
        .catch((error) => {
            console.log(error);
            return false;
        });

    return response;
}

async function registro(formulario) {

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        redirect: 'follow',

        body: JSON.stringify(formulario),
    };

    const response = await fetch(`${Constantes.API_URL}register`, requestOptions)
        .then((data) => {

            if (data.status !== 200) {
                return data.json()
            }
            else {
                return true;
            }

        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;
}


function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('userLogged');
    localStorage.removeItem('userToken');
}

async function guardar(formulario) {

    const requestOptions = {
        method: 'POST',
        headers: Constantes.getAuthHeaders(),
        body: JSON.stringify(formulario),
    };

    const response = await fetch(`${Constantes.API_URL}user/guardar`, requestOptions)
        .then((data) => {

            if (data.status !== 200) {
                return data.json()
            }
            else {
                return true;
            }
        })
        .catch((error) => {
            console.log(error);
            return { message: error };
        });

    return response;

}

// esta función valida si el token guardado sigue siendo válido
async function validaToken() {

    const requestOptions = {
        method: 'GET',
        headers: Constantes.getAuthHeaders()
    };
    
    const response = await fetch(`${Constantes.API_URL}user/validatoken`, requestOptions)
        .then((data) => {
            return data.status === 200;
        })
        .catch((error) => {
            return false;
        });

    return response;

}




// obtener todos los usuarios
async function getAll() {
    return await getGeneral('user/getall');
}
