import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import { Button, IconButton } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import { Container, TextField } from '@material-ui/core';

import { FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox } from '@material-ui/core/';

import DeleteIcon from '@material-ui/icons/Delete';


// utilidades
import { ConfirmDialog } from '../../../component/utils';

import { BotMenuItem } from '../../bot/botMenuItem';


// entity para la simulacion
import { Simulacion } from '../../../entity/simulacion';
import { World } from '../../../entity/world';


import { EventoViewWorldEdit } from '../../evento/eventoViewWorldEdit';


export function TareaRandomViewdEdit(props) {

  // inicializamos el hook con el estado
  const [state, setState] = useState(props.item);

  // inicializamos el hook de la lista de secciones
  const [seccionesList, setSeccionesList] = useState([]);

  // tipos de eventos
  const [tiposEventos, setTiposEventos] = useState([]);
  // evento seleccionado
  const [evento, setEvento] = useState('');
  // tarea seleccionada  
  const [tarea, setTarea] = useState('');
  // atributos para modificar de un bot
  const [listaAtributoBotModificar, setListaAtributoBotModificar] = useState([]);
  // atributo seleccionado
  const [atributo, setAtributo] = useState('');
  // valor del atributo seleccionado
  const [valorAtributo, setValorAtributo] = useState('');


  // pantalla de confirmacion
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  // función que controla los cambios en los textfield
  const handleInputChange = e => {
    const { name, value } = e.target
    setState({ ...state, [name]: value })
  }

  // comprobamos si hemos cambiado de registro para mostrar
  useEffect(() => {
    setState(props.item);
  }, [props.item]);


  // cargamos los tipos de eventos
  // cargamos los atributos que se pueden modificar de un bot
  useEffect(() => {
    World.getTiposEventos().then((data) => {
      setTiposEventos(data.records);
    });

    World.getAtributosBotModificar().then((data) => {
      setListaAtributoBotModificar(data.records);
    });
  }, []);

  // comprobamos si hemos cambiado de registro fases
  useEffect(() => {
    let tmp = props.fases.filter(x => x.nombre === state.fase);
    if (tmp.length > 0) {
      setSeccionesList(tmp[0].secciones);
    }
    else {
      setSeccionesList([]);
    }

  }, [state.fase]);


  // funcion para añadr un evento de mensaje
  const saveEvento = (element) => {
    let data = new FormData();
    data.append('targetid', state._id); // id de la tarea
    data.append('tipo', evento);

    if (element) {
      data.append('id', element.id); // id del evento a modificar
    }

    let content = {};

    if (tarea) {
      let tareaSeleccionada = props.tareas.filter(x => x._id === tarea)[0];

      content.tareaTarget = tareaSeleccionada.id;
      content.simulacionTarget = tareaSeleccionada.simulacionTarget;
      content.info = tareaSeleccionada.nombre;

    }

    content.atributo = atributo;
    content.valor = valorAtributo;

    data.append('data', JSON.stringify(content));

    Simulacion.addEventoTarea(data).then(() => {
      // recargamos esta tarea
      Simulacion.getTareas(state.simulacionTarget).then((data) => {
        let tarea = data.records.filter(x => x._id === state._id);

        setState(tarea[0]);
      });
    });
  };


  // funcion para borrar un evento de mensaje
  const deleteEvento = (element) => {

    Simulacion.deleteEventoTarea({ targetid: state._id, id: element }).then(() => {
      // recargamos esta tarea
      Simulacion.getTareas(state.simulacionTarget).then((data) => {
        let tarea = data.records.filter(x => x._id === state._id);

        setState(tarea[0]);
      });
    });

  };


  return (
    <Container maxWidth='lg' className='background-forms-secundario'>

      <ConfirmDialog
        content="¿Borrar registro?"
        open={openConfirmationDialog}
        setOpen={(value) => setOpenConfirmationDialog(value)}
        onConfirm={() => props.onDelete(state)}
      />

      <Typography align="center" variant="h5" style={{ margin: '10px' }}>{'Tarea de tipo Random'}</Typography>

      <Container maxWidth='xl'>
        <Box>

          <TextField
            margin='dense'
            size='small'
            variant='standard'
            fullWidth
            id="nombre"
            label='Nombre de tarea'
            name='nombre'
            autoComplete='nombre'

            value={state.nombre}
            onChange={handleInputChange}
          />

          <FormControl fullWidth>
            <InputLabel id="set-ubicacion">Ubicación</InputLabel>
            <Select
              labelId="set-ubicacion"
              id="ubicacion"
              value={state.ubicacion}
              onChange={(e) => setState({ ...state, 'ubicacion': e.target.value })}
            >
              {
                props.ubicaciones.map((element) => {
                  return <MenuItem value={element._id} key={element._id}>{element.nombre}</MenuItem>
                })
              }
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="set-canalComunicacion">Canal Comunicación</InputLabel>
            <Select
              labelId="set-canalComunicacion"
              id="canalComunicacion"
              value={state.canalComunicacion}
              onChange={(e) => setState({ ...state, 'canalComunicacion': e.target.value })}
            >
              {
                props.canalComunicacion.map((element) => {
                  return <MenuItem value={element} key={element}>{element}</MenuItem>
                })
              }
            </Select>
          </FormControl>

          <Box display='flex'>
            <FormControl fullWidth>
              <InputLabel id="set-fase">Fase</InputLabel>
              <Select
                labelId="set-fase"
                id="fase"
                value={state.fase}
                onChange={(e) => {
                  setState({ ...state, 'fase': e.target.value });
                }}
              >
                <MenuItem value={{ orden: 0, nombre: '' }} key={'no-selecciondo-fase'}>{'SIN SELECCIONAR'}</MenuItem>
                {
                  props.fases.map((element) => {
                    return <MenuItem value={element.nombre} key={element.orden + element.nombre}>{`${element.orden} ${element.nombre}`}</MenuItem>
                  })
                }
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel id="set-seccion">Seccion</InputLabel>
              <Select
                labelId="set-seccion"
                id="seccion"
                value={state.seccion}
                onChange={(e) => setState({ ...state, 'seccion': e.target.value })}
              >
                <MenuItem value={{ orden: 0, nombre: '' }} key={'no-selecciondo-seccion'}>{'SIN SELECCIONAR'}</MenuItem>
                {
                  seccionesList.map((element) => {
                    return <MenuItem value={element.nombre} key={element.orden + element.nombre}>{`${element.orden} ${element.nombre}`}</MenuItem>
                  })
                }
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel id="set-situacion">Situacion</InputLabel>
              <Select
                labelId="set-situacion"
                id="situacion"
                value={state.situacion}
                onChange={(e) => setState({ ...state, 'situacion': e.target.value })}
              >
                {
                  props.situaciones && props.situaciones.map((element) => {
                    return <MenuItem value={element.nombre} key={element.nombre}>{element.nombre}</MenuItem>
                  })
                }
              </Select>
            </FormControl>

          </Box>

          <FormControl fullWidth>
            <InputLabel id="set-bot">Bot</InputLabel>
            <Select
              labelId="set-bot"
              id="bot"
              value={state.bot}
              onChange={(e) => setState({ ...state, 'bot': e.target.value })}
            >
              {
                props.bots.map((element) => {
                  return <MenuItem value={element._id} key={element._id}><BotMenuItem item={element} /></MenuItem>
                })
              }
            </Select>
          </FormControl>

          <TextField
            margin='dense'
            size='small'
            variant='standard'
            fullWidth
            label='Probabilidad Ocurrencia'
            type="number"

            value={state.probabilidad}
            onChange={(e) => setState({ ...state, 'probabilidad': e.target.value })}
          />

        </Box>

        <Box>
          <FormControl fullWidth>
            <InputLabel id="set-evento">Evento</InputLabel>
            <Select
              labelId="set-evento"
              id="evento"
              value={evento}
              onChange={(e) => setEvento(e.target.value)}
            >
              {
                tiposEventos.map((element) => {
                  return <MenuItem value={element.valor} key={element.valor}>{element.nombre}</MenuItem>
                })
              }
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="set-tarea">Tarea</InputLabel>
            <Select
              labelId="set-tarea"
              id="tarea"
              value={tarea}
              onChange={(e) => setTarea(e.target.value)}
            >
              {
                props.tareas.filter(x => x._id !== state.tareaTarget).map((element) => {
                  return <MenuItem value={element._id} key={element._id}>{element.nombre}</MenuItem>
                })
              }
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="set-atributo">Atributo</InputLabel>
            <Select
              labelId="set-atributo"
              id="atributo"
              value={atributo}
              onChange={(e) => setAtributo(e.target.value)}
            >
              {
                listaAtributoBotModificar.filter(x => x.evento === evento).map((element, index) => {
                  return <MenuItem value={element.valor} key={index}>{element.nombre}</MenuItem>
                })
              }
            </Select>
          </FormControl>

          <TextField
            margin='dense'
            size='small'
            variant='standard'
            fullWidth
            label='Valor a añadir al atributo'

            value={valorAtributo}
            onChange={(e) => setValorAtributo(e.target.value)}
          />

          <div style={{ height: 20 }}></div>

          <Box display='flex' justifyContent='flex-end' width={1}>

            <Button
              variant="contained"
              color="default"
              style={{ margin: '5px' }}
              onClick={() => { saveEvento() }}
              disabled={
                evento === ''
              }
            >
              ADD Acción
            </Button>

          </Box>

          <Box m={1}>
            {
              state.mensajesLanzar.map((element, index) => {
                return (<Box display='flex' key={element.id}>
                  <EventoViewWorldEdit item={element} />

                  <IconButton onClick={() => {
                    deleteEvento(element.id)
                  }} >
                    <DeleteIcon />

                  </IconButton>

                </Box>);
              })
            }
          </Box>
        </Box>

      </Container>

      <div style={{ height: 20 }}></div>

      <Box display='flex' justifyContent='flex-end' width={1}>

        {
          props.onDelete ?
            <Button
              variant="contained"
              color="secondary"
              style={{ margin: '5px' }}
              onClick={() => setOpenConfirmationDialog(true)}
            >
              Borrar
            </Button>
            : <div></div>
        }

        <Button
          variant="contained"
          color="default"
          style={{ margin: '5px' }}
          onClick={() => props.cancel()}
        >
          Cancelar
        </Button>

        <Button
          variant="contained"
          color="primary"
          style={{ margin: '5px' }}
          onClick={() => {
            props.save(state);
            props.cancel();
          }}

        >
          Guardar
        </Button>
      </Box>

    </Container >

  );
};