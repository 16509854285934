import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import { Button, ButtonGroup } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import { Container, IconButton, TextField } from '@material-ui/core';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import { FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox } from '@material-ui/core/';
import { AppBar, Tabs, Tab } from '@material-ui/core';

// entity para la simulacion
import { Simulacion } from '../../../entity/simulacion';

// vista para elementos de slides
import { SlideViewWorld } from '../../slide/slideViewWorld';
import { SlideViewWorldEdit } from '../../slide/slideViewWorldEdit';

// vista para elementos de respuestas
import { RespuestaViewWorld } from '../../respuesta/world/respuestaViewWorld';
import { RespuestaViewEdit } from '../../respuesta/editorsimulacion/respuestaViewEdit';

// componente para la gestión de periodos de tiempo
import TranscursoTiempo from '../../../component/transcursoTiempo';

// utilidades
import { ColoresPaletaAzul, ConfirmDialog } from '../../../component/utils';

import { BotMenuItem } from '../../bot/botMenuItem';


export function TareaInteraccionViewEdit(props) {

  // inicializamos el hook con el estado
  const [state, setState] = useState(props.item);

  // inicializamos el hook de elementShow
  const [elementShow, setElementShow] = useState({});

  // inicializamos el hook de la lista de respuestas
  const [respuestas, setRespuestas] = useState([]);

  // inicializamos el hook de la lista de secciones
  const [seccionesList, setSeccionesList] = useState([]);

  // pantalla de confirmacion
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  // tab a mostrar
  const [tabIndex, setTabIndex] = useState(0);


  // ref para el input de tipo file
  const hiddenFileInput = React.createRef();

  // función que controla los cambios en los textfield
  const handleInputChange = e => {
    console.log(e.target);
    const { name, value } = e.target
    setState({ ...state, [name]: value })
  }

  // comprobamos si hemos cambiado de registro para mostrar
  useEffect(() => {
    setState(props.item);
  }, [props.item]);

  // comprobamos si hemos cambiado de registro fases
  useEffect(() => {
    let tmp = props.fases.filter(x => x.nombre === state.fase);
    if (tmp.length > 0) {
      setSeccionesList(tmp[0].secciones);
    }
    else {
      setSeccionesList([]);
    }

  }, [state.fase]);

  // obtenemos las respuestas de esta tarea
  useEffect(() => {
    Simulacion.getRespuestaTarea(state._id).then((data) => {
      setRespuestas(data.records);
    });
  }, []);


  // render para los objetos de edición
  const renderElementShowEdit = (element) => {

    // slide
    if (element.className === 'Slide') {
      return <Dialog open={elementShow.id !== undefined} maxWidth='md' fullScreen >
        <DialogContent>
          <SlideViewWorldEdit item={elementShow} save={(data) => saveSlide(data)} cancel={() => setElementShow()} />
        </DialogContent>
      </Dialog>;
    }

    // respuesta
    if (element.className === 'Respuesta') {
      return <RespuestaViewEdit item={elementShow}
        tareas={props.tareas}
        skills={props.skills}
        save={(data) => saveRespuesta(data)}
        cancel={() => setElementShow()} />;
    }

  }


  // funcion para guardar el slide
  const saveSlide = (element) => {
    let data = new FormData();
    data.append('targetid', state._id);

    if (element) {
      data.append('id', element.id);
      data.append('nombre', element.nombre);
      data.append('orden', element.orden);

      // si hemos indicado una nueva imagen, la enviamos
      if (element.imagenData) {
        data.append('fichero', element.imagenData);
      }

    }

    Simulacion.addSlideTarea(data).then(() => {
      Simulacion.getTareas(state.simulacionTarget)
        .then((data) => {
          if (data.records) {
            let tareas = data.records.filter(x => x._id.toString() === state._id.toString());

            if (tareas.length > 0) {
              setState({ ...state, 'slides': tareas[0].slides });
            }

            setElementShow();
          }
        });
    });

  };

  // funcion para guardar una respuesta
  const saveRespuesta = (element) => {
    let data = new FormData();
    data.append('targetid', state._id);

    if (element) {
      data.append('_id', element._id);
      data.append('orden', element.orden);
      if (element.descripcion) data.append('descripcion', element.descripcion);
      data.append('correcta', element.correcta ? element.correcta : false);
      data.append('finalizarTarea', element.finalizarTarea ? element.finalizarTarea : false);
      if (element.mensajeCorrecto) data.append('mensajeCorrecto', element.mensajeCorrecto);
      if (element.mensajeIncorrecto) data.append('mensajeIncorrecto', element.mensajeIncorrecto);
      if (element.skills) data.append('skills', JSON.stringify(element.skills));
    }

    Simulacion.addRespuestaTarea(data).then(() => {
      Simulacion.getRespuestaTarea(state._id).then((data) => {
        setRespuestas(data.records);
      });
    });
  };

  const deleteRespuesta = (id) => {
    Simulacion.deleteRespuestaTarea({ targetid: id }).then((respuesta) => {
      if (respuesta) {
        Simulacion.getRespuestaTarea(state._id).then((data) => {
          setRespuestas(data.records);
        });
      }
    });
  }

  const deleteSlide = (id) => {
    Simulacion.deleteSlideTarea({ targetid: state._id, id: id }).then((respuesta) => {
      if (respuesta) {
        props.onCargar();
      }
    });
  }

  return (
    <Container maxWidth='lg' className='background-forms-secundario'>

      <ConfirmDialog
        content="¿Borrar registro?"
        open={openConfirmationDialog}
        setOpen={(value) => setOpenConfirmationDialog(value)}
        onConfirm={() => props.onDelete(state)}
      />

      <Box>

        <AppBar position="static">
          <Tabs value={tabIndex} onChange={(e, value) => setTabIndex(value)} aria-label="simple tabs example">
            <Tab label="General" id={0} />
            <Tab label="Slides" id={1} />
            <Tab label="Respuestas" id={2} />
          </Tabs>
        </AppBar>


        {tabIndex === 0 && (
          <Box>

            <Typography align="center" variant="h5" style={{ margin: '10px' }}>{'Tarea de tipo Interacción'}</Typography>

            <Box>
              <input ref={hiddenFileInput} accept='image/*' type='file' style={{ display: 'none' }}
                onChange={e => {
                  setState({
                    ...state,
                    imagenData: e.target.files[0],
                    imagenAvatar: URL.createObjectURL(e.target.files[0])
                  });
                  //setState({ ...state, imagenAvatar: URL.createObjectURL(e.target.files[0]) });
                }} />
              <Avatar style={{ marginRight: '10px' }}>
                <IconButton onClick={() => { hiddenFileInput.current.click(); }} >
                  <PhotoCameraIcon />
                </IconButton>
              </Avatar>

              <Box display='flex' justifyContent='center' width={1}>
                <img
                  alt={state.nombre}
                  src={state.imagenAvatar}
                  style={{ margin: '10px', maxWidth: '100%', maxHeight: '200px' }}
                />
              </Box>

            </Box>

            <Container maxWidth='xl'>
              <Box>

                <TextField
                  margin='dense'
                  size='small'
                  variant='standard'
                  fullWidth
                  id="nombre"
                  label='Nombre de tarea'
                  name='nombre'
                  autoComplete='nombre'

                  value={state.nombre}
                  onChange={handleInputChange}
                />

                <FormControl fullWidth>
                  <InputLabel id="set-ubicacion">Ubicación</InputLabel>
                  <Select
                    labelId="set-ubicacion"
                    id="ubicacion"
                    value={state.ubicacion}
                    onChange={(e) => setState({ ...state, 'ubicacion': e.target.value })}
                  >
                    {
                      props.ubicaciones.map((element) => {
                        return <MenuItem value={element._id} key={element._id}>{element.nombre}</MenuItem>
                      })
                    }
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <InputLabel id="set-canalComunicacion">Canal Comunicación</InputLabel>
                  <Select
                    labelId="set-canalComunicacion"
                    id="canalComunicacion"
                    value={state.canalComunicacion}
                    onChange={(e) => setState({ ...state, 'canalComunicacion': e.target.value })}
                  >
                    {
                      props.canalComunicacion.map((element) => {
                        return <MenuItem value={element} key={element}>{element}</MenuItem>
                      })
                    }
                  </Select>
                </FormControl>

                <Box display='flex'>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.daPasoSeccion}
                        onChange={(e) => {
                          setState({ ...state, 'daPasoSeccion': !state.daPasoSeccion });
                        }}
                        name={"daPasoSeccion"}
                        color="primary"
                      />
                    }
                    label="Da paso a la siguiente sección"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.ejecucionDirecta}
                        onChange={(e) => {
                          setState({ ...state, 'ejecucionDirecta': !state.ejecucionDirecta });
                        }}
                        name={"ejecucionDirecta"}
                        color="primary"
                      />
                    }
                    label="Ejecución directa"
                  />

                  <TextField
                    margin='dense'
                    size='small'
                    variant='standard'
                    id="orden"
                    label='Orden'
                    name='orden'
                    type={'number'}

                    value={state.orden}
                    onChange={handleInputChange}
                  />

                </Box>

                <Box display='flex'>
                  <FormControl fullWidth>
                    <InputLabel id="set-fase">Fase</InputLabel>
                    <Select
                      labelId="set-fase"
                      id="fase"
                      value={state.fase}
                      onChange={(e) => {
                        setState({ ...state, 'fase': e.target.value });
                      }}
                    >
                      <MenuItem value={{ orden: 0, nombre: '' }} key={'no-selecciondo-fase'}>{'SIN SELECCIONAR'}</MenuItem>
                      {
                        props.fases.map((element) => {
                          return <MenuItem value={element.nombre} key={element.orden + element.nombre}>{`${element.orden} ${element.nombre}`}</MenuItem>
                        })
                      }
                    </Select>
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel id="set-seccion">Seccion</InputLabel>
                    <Select
                      labelId="set-seccion"
                      id="seccion"
                      value={state.seccion}
                      onChange={(e) => setState({ ...state, 'seccion': e.target.value })}
                    >
                      <MenuItem value={{ orden: 0, nombre: '' }} key={'no-selecciondo-seccion'}>{'SIN SELECCIONAR'}</MenuItem>
                      {
                        seccionesList.map((element) => {
                          return <MenuItem value={element.nombre} key={element.orden + element.nombre}>{`${element.orden} ${element.nombre}`}</MenuItem>
                        })
                      }
                    </Select>
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel id="set-situacion">Situacion</InputLabel>
                    <Select
                      labelId="set-situacion"
                      id="situacion"
                      value={state.situacion}
                      onChange={(e) => setState({ ...state, 'situacion': e.target.value })}
                    >
                      {
                        props.situaciones && props.situaciones.map((element) => {
                          return <MenuItem value={element.nombre} key={element.nombre}>{element.nombre}</MenuItem>
                        })
                      }
                    </Select>
                  </FormControl>


                </Box>

                <FormControl fullWidth>
                  <InputLabel id="set-bot">Bot</InputLabel>
                  <Select
                    labelId="set-bot"
                    id="bot"
                    value={state.bot}
                    onChange={(e) => {
                      console.log(e.target.value);
                      setState({ ...state, 'bot': e.target.value });
                    }}
                  >
                    {
                      props.bots.map((element) => {
                        return <MenuItem value={element._id} key={element._id}><BotMenuItem item={element} /></MenuItem>
                      })
                    }
                  </Select>
                </FormControl>

                <Box display='flex' justifyContent='space-around'>
                  <TranscursoTiempo titulo={'Momento Activación'} item={state.momentoActivacion} onChange={(element) => setState({ ...state, 'momentoActivacion': element })} />
                  <TranscursoTiempo titulo={'Duración Tarea'} item={state.duracionTarea} onChange={(element) => setState({ ...state, 'duracionTarea': element })} />
                </Box>


              </Box>
            </Container>

          </Box>
        )}

        {tabIndex === 1 && (
          <Box display='flex' flexDirection='column'>
            <Typography align="center" variant="button">{'Slides'}</Typography>
            {state.slides.map((element) => {
              return <SlideViewWorld item={element} setElementShow={() => setElementShow(element)} key={element.id} onDelete={() => deleteSlide(element.id)} />;
            })}
          </Box>
        )}

        {tabIndex === 2 && (
          <Box display='flex' flexDirection='column'>
            <Typography align="center" variant="button">{'Respuestas'}</Typography>
            {respuestas.map((element) => {
              element.targetid = props.targetid;
              element.tarea = state.id;

              return <RespuestaViewWorld item={element} setElementShow={() => setElementShow(element)} key={element.id} onDelete={() => deleteRespuesta(element._id)} />;
            })}
          </Box>
        )}

      </Box>

      <div style={{ height: 20 }}></div>

      <Box display='flex' justifyContent='flex-end' width={1}>

        {
          props.onDelete ?
            <Button
              variant="contained"
              color="secondary"
              style={{ margin: '5px' }}
              onClick={() => setOpenConfirmationDialog(true)}
            >
              Borrar
            </Button>
            : <div></div>
        }

        <Button
          variant="contained"
          color="primary"
          style={{ margin: '5px' }}
          onClick={() => saveSlide()}
        >
          ADD Slide
        </Button>

        <Button
          variant="contained"
          color="primary"
          style={{ margin: '5px' }}
          onClick={() => saveRespuesta()}
        >
          ADD RESPUESTA
        </Button>

        <Button
          variant="contained"
          color="default"
          style={{ margin: '5px' }}
          onClick={() => props.cancel()}
        >
          Cancelar
        </Button>

        <Button
          variant="contained"
          color="primary"
          style={{ margin: '5px' }}
          onClick={() => {
            props.save(state);
            props.cancel();
          }}
        >
          Guardar
        </Button>
      </Box>

      {elementShow ? renderElementShowEdit(elementShow) : ''}

    </Container >

  );
};