import React from 'react';

import { Box, Container } from '@material-ui/core';

import { List, Typography, TextField } from '@material-ui/core';

import Header from '../../component/header';
import { setLoading } from '../../component/utils';

import { World } from '../../entity/world';
import { Simulacion } from '../../entity/simulacion';

// vista para elementos de segmento
import { SegmentoViewWorld } from '../segmento/segmentoViewWorld';

// utilidades
import { ConfirmDialog } from '../../component/utils';


class NuevaPlantilla extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      segmentos: [],

      nombreNuevaPlantilla: '',
      segmentoSeleccionado: undefined,

      openConfirmationDialog: false,
    }


    this.onCargar = this.onCargar.bind(this);
    this.addPlantilla = this.addPlantilla.bind(this);

  }

  onCargar() {

    this.setState({ loading: true });

    World.getSegmentos()
      .then(
        data => {
          if (data.records) {
            this.setState({ segmentos: data.records, loading: false });
          }
        }
      );

  }

  componentDidMount() {
    this.onCargar();
  }

  addPlantilla() {
    let data = new FormData();
    data.append('nombre', this.state.nombreNuevaPlantilla);
    data.append('segmento', this.state.segmentoSeleccionado._id);

    Simulacion.addPlantilla(data)
      .then((response) => {
        if (response) {
          window.location.href = "/editor/" + response[0]._id;
        }
      });
  }


  render() {

    return (
      <div className="background">

        <Header title="Nueva plantilla" history={this.props.history}></Header>

        <div style={{ height: 20 }}></div>

        <Container maxWidth="sm">

          <ConfirmDialog
            content={`¿Crear plantila ${this.state.segmentoSeleccionado ? this.state.segmentoSeleccionado.nombre : ''} ?`}
            open={this.state.openConfirmationDialog}
            setOpen={(value) => this.setState({ openConfirmationDialog: value })}
            onConfirm={() => this.addPlantilla()}
          />


          <Box>
            <Typography align="center" variant="h6">{'1) Indique el nombre para la nueva plantilla'}</Typography>

            <Box className='background-list-cards' display='flex' m={1} p={1}>
              <TextField
                margin='dense'
                size='small'
                variant='standard'
                fullWidth
                id="nombreNuevaPlantilla"
                label='Generar nueva plantilla de este segmento'
                name='nombreNuevaPlantilla'

                error={this.state.nombreNuevaPlantilla.length > 0 && this.state.nombreNuevaPlantilla.length < 6}
                helperText={'6 carácteres mínimo'}

                value={this.state.nombreNuevaPlantilla}
                onChange={(e) => this.setState({ nombreNuevaPlantilla: e.target.value })}
              />

            </Box>

            <List>
              <Typography align="center" variant="h6">{'2) Seleccione el segmento al al que se asignará la plantilla'}</Typography>
              {this.state.segmentos.map((element) => {
                return <SegmentoViewWorld item={element} setElementShow={(seleccionado) => {
                  if (this.state.nombreNuevaPlantilla.length >= 6) {
                    this.setState({ segmentoSeleccionado: seleccionado }, () => this.setState({ openConfirmationDialog: true }));
                  }
                }} key={element.id} />
              })
              }
            </List>


            <div style={{ height: 20 }}></div>

            <Box display="flex" justifyContent="center">
              {this.state.loading ? setLoading() : ''}
            </Box>

          </Box>


        </Container>
      </div>
    );
  }
}

export default NuevaPlantilla;