import React, { useEffect, useState } from 'react';

import { Box, Collapse } from '@material-ui/core';
import { Button, IconButton } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DeleteIcon from '@material-ui/icons/Delete';

import { Container, Avatar, TextField } from '@material-ui/core';

import { FormControlLabel, Checkbox } from '@material-ui/core';

import { Stepper, Step, StepLabel } from '@material-ui/core';


// utilidades
import { ConfirmDialog } from '../../component/utils';



export function SegmentoViewWorldEdit(props) {

  // inicializamos el hook con el estado
  const [state, setState] = useState(props.item);

  // pantalla de confirmacion
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  // configuración de los steps
  const [steps, setSteps] = useState([]);
  const [stepOptional, setStepOptional] = useState([]);
  const [stepSkipped, setStepSkipped] = useState([]);
  const [activeStep, setActiveStep] = useState(0);

  // función que controla los cambios en los textfield
  const handleInputChange = e => {
    const { name, value } = e.target
    setState({ ...state, [name]: value })
  }

  // genero los steps
  useEffect(() => {
    cargaStepsFromFases();
  }, []);

  const cargaStepsFromFases = () => {
    // genero los steps
    let tmpSteps = [];

    if (state.fases) {
      state.fases.forEach((element) => tmpSteps.push(element.nombre));

      setSteps(tmpSteps);
    }
  }

  // función para cambiar el orden o nombre de una fase
  const handleInputChangeFase = e => {
    const { name, value } = e.target

    let fases = [...state.fases]

    // cambiamos el nombre
    if (name.toString().indexOf('nombre') >= 0) {
      fases[activeStep].nombre = value;
    }

    // cambiamos el orden
    if (name.toString().indexOf('orden') >= 0) {
      fases[activeStep].orden = value;
    }

    setState({ ...state, 'fases': fases })
  }

  // función para cambiar el orden o nombre de una secccion
  const handleInputChangeSeccion = (e, index) => {

    const { name, value } = e.target

    let fases = [...state.fases]

    // cambiamos el nombre
    if (name.toString().indexOf('nombre') >= 0) {
      fases[activeStep].secciones[index].nombre = value;
    }

    // cambiamos el orden
    if (name.toString().indexOf('orden') >= 0) {
      fases[activeStep].secciones[index].orden = value;
    }


    setState({ ...state, 'fases': fases })
  }

  // función que controla los cambios en los checkbox
  const handleInputChangeChecked = (e, index) => {
    let fases = [...state.fases]

    fases[activeStep].secciones[index].tareasGlobales = e.target.checked;

    setState({ ...state, 'fases': fases })
  }


  const deleteSeccion = (index) => {
    let fases = state.fases;
    fases[activeStep].secciones.splice(index, 1);
    setState({ ...state, 'fases': fases });
  }

  const siguienteStep = () => {
    if (activeStep < (state.fases.length - 1)) {
      setActiveStep(activeStep + 1);
    }
  }

  const anteriorStep = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  }

  const resetStep = () => {
    setActiveStep(0);
  }


  return (
    <Container maxWidth='lg'>

      <ConfirmDialog
        content="¿Borrar registro?"
        open={openConfirmationDialog}
        setOpen={(value) => setOpenConfirmationDialog(value)}
        onConfirm={() => props.onDelete(state)}
      />

      <Box className='background-forms'>

        <TextField
          margin='dense'
          size='small'
          variant='standard'
          fullWidth
          id="nombre"
          label='Nombre'
          name='nombre'
          autoComplete='nombre'

          value={state.nombre}
          onChange={handleInputChange}
        />

        <div style={{ height: 20 }}></div>

        <Box display='flex' justifyContent='flex-end' width={1}>

          {
            props.onDelete ?
              <Button
                variant="contained"
                color="secondary"
                style={{ margin: '5px' }}
                onClick={() => setOpenConfirmationDialog(true)}
              >
                Borrar
          </Button>
              : <div></div>
          }

          <Button
            variant="contained"
            color="default"
            style={{ margin: '5px' }}
            onClick={() => props.cancel()}
          >
            Cancelar
          </Button>

          {state.nivel === 0 ? <Button
            variant="contained"
            color="primary"
            style={{ margin: '5px' }}
            onClick={() => {
              let tmp = {};
              tmp.segmentoPadre = state._id;
              tmp.nivel = state.nivel + 1;
              tmp.fases = state.fases;
              props.save(tmp);
            }}
          >
            Añadir Sub Segmento
          </Button>
            : <div></div>
          }

          <Button
            variant="contained"
            color="primary"
            style={{ margin: '5px' }}
            onClick={() => props.save(state)}
          >
            Guardar
          </Button>
        </Box>

        <Box>

          <div style={{ height: 20 }}></div>

          <Box display='flex' justifyContent='flex-end' width={1}>

            <Button
              variant="outlined"
              size='small'
              color="secondary"
              style={{ margin: '5px' }}
              onClick={() => {
                let fases = state.fases;
                fases.push({ nombre: 'fase', orden: 0, secciones: [{ orden: 0, nombre: 'seccion' }] });
                setState({ ...state, 'fases': fases });

                let tmpSteps = [];

                if (state.fases) {
                  state.fases.forEach((element) => tmpSteps.push(element.nombre));

                  setSteps(tmpSteps);
                }

                cargaStepsFromFases();


              }}
            >
              Añadir Nueva Fase
            </Button>
          </Box>

          <Box>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                if (stepOptional.indexOf(index) >= 0) {
                  labelProps.optional = <Typography variant="caption">Opcional</Typography>;
                }
                if (stepSkipped.indexOf(index) < 0) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={index} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>

            {activeStep === steps.length ? (
              <div>
                <Typography>Final del wizard</Typography>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: '5px' }}
                  onClick={resetStep}>Reset</Button>
              </div>)
              :
              (
                <div>

                  <Button
                    variant="contained"
                    color="primary"
                    style={{ margin: '5px' }}
                    disabled={activeStep === 0}
                    onClick={anteriorStep} >
                    Anterior
                          </Button>

                  <Button
                    variant="contained"
                    color="primary"
                    style={{ margin: '5px' }}
                    disabled={stepOptional.indexOf(activeStep) < 0}
                    onClick={siguienteStep}
                  >
                    Saltar
                            </Button>


                  <Button
                    variant="contained"
                    color="primary"
                    style={{ margin: '5px' }}
                    onClick={siguienteStep}
                  >
                    {activeStep === steps.length - 1 ? 'Terminado' : 'Siguiente'}
                  </Button>
                </div>
              )
            }

          </Box>

          <div style={{ height: 20 }}></div>

          <Container maxWidth='md'>
            <Box className='background-list-cards' m={1}>
              <TextField
                margin='dense'
                size='small'
                variant='standard'
                id={'orden'}
                label='Orden'
                name={'orden'}
                value={state.fases[activeStep].orden}
                onChange={handleInputChangeFase}
              />

              <TextField
                margin='dense'
                size='small'
                variant='standard'
                id={'nombre'}
                label='Fase'
                name={'nombre'}
                value={state.fases[activeStep].nombre}
                onChange={handleInputChangeFase}
              />

              <IconButton onClick={() => {
                let fases = state.fases;
                fases[activeStep].secciones.push({ orden: 0, nombre: 'seccion' });
                setState({ ...state, 'fases': fases });
              }}>
                <AddCircleOutlineIcon />
              </IconButton>

              <IconButton onClick={() => {
                let fases = state.fases;
                fases.splice(activeStep, 1);
                setState({ ...state, 'fases': fases });

                anteriorStep();

                cargaStepsFromFases();
              }}>
                <DeleteOutlineIcon />
              </IconButton>

            </Box>


            {
              state.fases && state.fases[activeStep] && state.fases[activeStep].secciones
                ?
                state.fases[activeStep].secciones.map((element, index) => {
                  return <Box key={index} m={2} >
                    <TextField
                      margin='dense'
                      size='small'
                      variant='standard'
                      id={'orden' + index}
                      label='Orden'
                      name={'orden' + index}
                      value={element.orden}
                      onChange={(e) => handleInputChangeSeccion(e, index)}
                    />


                    <TextField
                      margin='dense'
                      size='small'
                      variant='standard'
                      id={'nombre' + index}
                      label='Sección'
                      name={'nombre' + index}
                      value={element.nombre}
                      onChange={(e) => handleInputChangeSeccion(e, index)}
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={element.tareasGlobales}
                          onChange={(e) => handleInputChangeChecked(e, index) }
                          name={"tateasglobales" + index}
                          color="primary"
                        />
                      }
                      label="Tareas Globales"
                    />

                    <IconButton onClick={() => deleteSeccion(index)}>
                      <DeleteOutlineIcon />
                    </IconButton>

                  </Box>

                })
                : <div>{'fase no existe'}</div>
            }


          </Container>

        </Box>

      </Box>

    </Container>

  );
};