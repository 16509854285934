import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { FormControlLabel, Checkbox } from '@material-ui/core';

import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import DeleteIcon from '@material-ui/icons/Delete';
import BackupIcon from '@material-ui/icons/Backup';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import { Container, IconButton, TextField } from '@material-ui/core';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core/';

// componente para la gestión de periodos de tiempo
import TranscursoTiempo from '../../component/transcursoTiempo';

import { ContainerSkills } from '../../component/containerSkills';

// utilidades
import { ConfirmDialog } from '../../component/utils';

import { BotMenuItem } from '../bot/botMenuItem';

import { Simulacion } from '../../entity/simulacion';
import { Constantes } from '../../util/constantes';


export function SimulacionViewWorldEdit(props) {

  // inicializamos el hook con el estado
  const [state, setState] = useState(props.item);

  // pantalla de confirmacion
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);


  // ref para el input de tipo file
  const hiddenFileInput = React.createRef();
  const hiddenFileInputCSV = React.createRef();
  const hiddenFileInputCSVDownload = React.createRef();

  // función que controla los cambios en los textfield
  const handleInputChange = e => {
    const { name, value } = e.target
    setState({ ...state, [name]: value })
  }

  // función que controla los cambios en los checkbox
  const handleInputChangeChecked = e => {
    setState({ ...state, [e.target.name]: e.target.checked })
  }

  // comprobamos si hemos cambiado de registro para mostrar
  useEffect(() => {
    setState(props.item);
  }, [props.item]);

  useEffect(() => {
  }, [state.imagenAvatar]);

  // esta función añade un nuevo skill al item
  const addSkill = element => {
    let tmp = state.skills;

    // si el skill ya está añadido, no lo vuelvo a añadir
    let existe = tmp.filter(x => x.id === element.id).length;

    // si no existe, lo añado
    if (existe === 0) {
      tmp.push(element);
    }

    setState({ ...state, 'skills': tmp });
  }

  return (
    <Container maxWidth="md">

      <Box width={1} className="background-forms">

        <Typography align="center" variant="h6">{props.item.segmentoNombre}</Typography>

        <ConfirmDialog
          content="¿Borrar registro?"
          open={openConfirmationDialog}
          setOpen={(value) => setOpenConfirmationDialog(value)}
          onConfirm={() => props.onDelete(state)}
        />

        <Box display='flex' justifyContent="center">
          <img
            alt={state.nombre}
            src={state.imagenAvatar}
            style={{ margin: '10px', maxWidth: '100%', maxHeight: '200px' }}
          />
        </Box>

        <Box display='flex'>

          <input ref={hiddenFileInput} accept='image/*' type='file' style={{ display: 'none' }}
            onChange={e => {
              setState({
                ...state,
                imagenData: e.target.files[0],
                imagenAvatar: URL.createObjectURL(e.target.files[0])
              });
            }} />
          <Avatar style={{ marginRight: '10px' }}>
            <IconButton onClick={() => { hiddenFileInput.current.click(); }} >
              <PhotoCameraIcon />
            </IconButton>
          </Avatar>

          <TextField
            margin='dense'
            size='small'
            variant='standard'
            fullWidth
            id="nombre"
            label='Nombre'
            name='nombre'
            autoComplete='nombre'

            value={state.nombre}
            onChange={handleInputChange}
          />

        </Box>

        <Box>

          <TextField
            margin='dense'
            size='small'
            variant='standard'
            fullWidth
            id="descripcion"
            label='Descripción'
            name='descripcion'

            value={state.descripcion}
            onChange={handleInputChange}
          />

          <TextField
            margin='dense'
            size='small'
            variant='standard'
            fullWidth
            id="objetivo"
            label='Objetivo'
            name='objetivo'

            value={state.objetivo}
            onChange={handleInputChange}
          />

          <Box display='flex'>
            <TextField
              margin='dense'
              size='small'
              variant='standard'
              fullWidth
              id="presupuesto"
              label='Presupuesto'
              name='presupuesto'
              type='number'

              value={state.presupuesto}
              onChange={handleInputChange}
            />

            <TextField
              margin='dense'
              size='small'
              variant='standard'
              fullWidth
              id="nivel"
              label='Nivel'
              name='nivel'
              type='number'

              value={state.nivel}
              onChange={handleInputChange}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={state.enProduccion}
                  onChange={handleInputChangeChecked}
                  name="enProduccion"
                  color="primary"
                />
              }
              label="En Producción"
            />

            <TextField
              margin='dense'
              size='small'
              variant='standard'
              fullWidth
              id="segundosEsperaRespuesta"
              label='Segundos Espera Respuesta'
              name='segundosEsperaRespuesta'
              type='number'

              value={state.segundosEsperaRespuesta}
              onChange={handleInputChange}
            />


          </Box>

          <FormControl fullWidth>
            <InputLabel id="set-tipo">Tipo</InputLabel>
            <Select
              labelId="set-tipo"
              id="tipo"
              value={state.tipo}
              onChange={(e) => setState({ ...state, 'tipo': e.target.value })}
            >
              <MenuItem value={''} key={'seleccionar-tipo'}>{'Seleccionar'}</MenuItem>
              <MenuItem value={'Entrenamiento'} key={'Entrenamiento'}>{'Entrenamiento'}</MenuItem>
              <MenuItem value={'Examen'} key={'Examen'}>{'Examen'}</MenuItem>
              <MenuItem value={'Control'} key={'Control'}>{'Control'}</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="set-empresa">Empresa</InputLabel>
            <Select
              labelId="set-empresa"
              id="empresa"
              value={state.empresaTarget}
              onChange={(e) => setState({ ...state, 'empresaTarget': e.target.value })}
            >
              {
                props.empresas.map((element) => {
                  return <MenuItem value={element._id} key={element._id}>{element.nombre}</MenuItem>
                })
              }
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="set-bot">Bot</InputLabel>
            <Select
              labelId="set-bot"
              id="bot"
              value={state.bot}
              onChange={(e) => setState({ ...state, 'bot': e.target.value })}
            >
              {
                props.bots.map((element) => {
                  return <MenuItem value={element._id} key={element._id}><BotMenuItem item={element} /></MenuItem>
                })
              }
            </Select>
          </FormControl>

          <TranscursoTiempo titulo={'Duración'} item={state.duracion} onChange={(element) => setState({ ...state, 'duracion': element })} />

          <Typography align="center" variant="h6">{'Skills a mejorar'}</Typography>

          <Box display='flex' alignItems='flex-start'>
            {
              state.skills && state.skills.length > 0
                ? (
                  <Box className='background-list-cards' m={1} p={1}>
                    {
                      state.skills.map((element, index) => {
                        return <Box key={element.id + element.nombre} display='flex' alignItems='center'>

                          <Avatar
                            src={element.imagenAvatar}
                            alt={element.nombre}
                            title={element.nombre} />

                          <TextField
                            size='small'
                            variant='standard'
                            fullWidth
                            id="nivel"
                            label={element.nombre}
                            name='nivel'
                            type='number'
                            style={{ margin: 10 }}

                            value={element.valor}
                            onChange={(event) => {
                              let tmpSkill = state.skills;
                              tmpSkill[index].valor = event.target.value;
                              setState({ ...state, 'skills': tmpSkill });
                            }}
                          />

                          <IconButton onClick={() => {
                            let tmpSkill = state.skills;
                            tmpSkill.splice(index, 1);
                            setState({ ...state, 'skills': tmpSkill });
                          }} >
                            <DeleteIcon />
                          </IconButton>

                        </Box>
                      }
                      )
                    }
                  </Box>
                )
                : ''
            }

            <ContainerSkills skills={props.skills} addSkill={addSkill} />
          </Box>


        </Box>

        <div style={{ height: 20 }}></div>

        <Box display='flex' justifyContent='flex-end' width={1}>

          {
            props.onDelete ?
              <Button
                variant="contained"
                color="secondary"
                style={{ margin: '5px' }}
                onClick={() => setOpenConfirmationDialog(true)}
              >
                Borrar
              </Button>
              : <div></div>
          }

          <Button
            variant="contained"
            color="default"
            style={{ margin: '5px' }}
            onClick={() => props.cancel()}
          >
            Cancelar
          </Button>

          <Button
            variant="contained"
            color="primary"
            style={{ margin: '5px' }}
            onClick={() => props.save(state)}
          >
            Guardar
          </Button>
        </Box>

        <Box display='flex'>

          <input ref={hiddenFileInputCSV} accept='.csv' type='file' style={{ display: 'none' }}
            onChange={e => {

              let data = new FormData();
              data.append('targetid', state._id);
              data.append('fichero', e.target.files[0]);

              Simulacion.uploadTareasCSV(data).then((response) => {
                console.log(response);
              });

            }} />
          <Avatar style={{ marginRight: '10px' }}>
            <IconButton onClick={() => { hiddenFileInputCSV.current.click(); }} >
              <BackupIcon />
            </IconButton>
          </Avatar>

          <div style={{ width: 10 }}></div>

          <Avatar style={{ marginRight: '10px' }}>
            <IconButton onClick={() => {
              let data = new FormData();
              data.append('targetid', state._id);


              Simulacion.downloadPlantillaTareasCSV(data).then((response) => {
                console.log(response[0].url);
                window.open(Constantes.DOCUMENTOS_URI + response[0].url, "target=_blank");
              });
            }} >
              <CloudDownloadIcon />
            </IconButton>
          </Avatar>

        </Box>

      </Box>

    </Container>

  );
};