import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { Container, IconButton, TextField } from '@material-ui/core';
import { AppBar, Tabs, Tab } from '@material-ui/core';

import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import Chip from '@material-ui/core/Chip';

import { FormControl, FormControlLabel, Checkbox, InputLabel, Select, MenuItem } from '@material-ui/core/';

// entity para el mundo
import { World } from '../../../entity/world';

// vista para elementos de slides
import { SlideViewWorld } from '../../slide/slideViewWorld';
import { SlideViewWorldEdit } from '../../slide/slideViewWorldEdit';

// componente para la gestión de periodos de tiempo
import TranscursoTiempo from '../../../component/transcursoTiempo';

// utilidades
import { ConfirmDialog } from '../../../component/utils';

import { BotMenuItem } from '../../bot/botMenuItem';


export function TareaBuildViewWorldEdit(props) {

  // inicializamos el hook con el estado
  const [state, setState] = useState(props.item);

  // para añadir tags
  const [tag, setTag] = useState('');

  // inicializamos el hook de elementShow
  const [elementShow, setElementShow] = useState(undefined);

  // pantalla de confirmacion
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  // tab a mostrar
  const [tabIndex, setTabIndex] = useState(0);

  // ref para el input de tipo file
  const hiddenFileInput = React.createRef();

  // función que controla los cambios en los textfield
  const handleInputChange = e => {
    const { name, value } = e.target
    setState({ ...state, [name]: value })
  }

  // comprobamos si hemos cambiado de registro para mostrar
  useEffect(() => {
    setState(props.item);
  }, [props.item]);

  useEffect(() => {
  }, [state.imagenAvatar]);

  // funcion para guardar el slide
  const saveSlide = (element) => {
    let data = new FormData();
    data.append('targetid', state._id);

    if (element) {
      data.append('id', element.id);
      data.append('nombre', element.nombre);
      data.append('orden', element.orden);

      // si hemos indicado una nueva imagen, la enviamos
      if (element.imagenData) {
        data.append('fichero', element.imagenData);
      }

    }

    World.addSlideTarea(data).then(() => {
      props.onCargar();
    });
  };

  // añadimos un nuevo tag
  const addTag = () => {
    let tmp = state.tags;
    tmp.push(tag);
    setState({ ...state, tags: tmp });
    setTag('');
  }

  // borraos un tag
  const deleteTag = (element) => {
    let tmp = state.tags;
    tmp.splice(tmp.indexOf(element), 1);

    setState({ ...state, tags: tmp });
    setTag('');
  }

  const deleteSlide = (id) => {
    World.deleteSlideTarea({ targetid: state._id, id: id }).then((respuesta) => {
      if (respuesta) {
        props.onCargar();
      }
    });
  }

  return (
    <Container maxWidth='md'>

      <ConfirmDialog
        content="¿Borrar registro?"
        open={openConfirmationDialog}
        setOpen={(value) => setOpenConfirmationDialog(value)}
        onConfirm={() => props.onDelete(state)}
      />

      <Box className='background-forms'>

        <Box>

          {
            elementShow
              ? (<Dialog open={elementShow.id !== undefined} maxWidth='md' fullWidth >
                <DialogContent>
                  <SlideViewWorldEdit item={elementShow} save={(data) => saveSlide(data)} cancel={() => setElementShow()} />
                </DialogContent>
              </Dialog>)
              : ''
          }

          <AppBar position="static">
            <Tabs value={tabIndex} onChange={(e, value) => setTabIndex(value)} aria-label="simple tabs example">
              <Tab label="General" id={0} />
              <Tab label="Slides" id={1} />
            </Tabs>
          </AppBar>

          {tabIndex === 0 && (
            <Box>

              <Box display='flex' justifyContent='center'>
                <Typography align="center" variant="button">{'Build'}</Typography>
              </Box>

              <Box>
                <input ref={hiddenFileInput} accept='image/*' type='file' style={{ display: 'none' }}
                  onChange={e => {
                    setState({
                      ...state,
                      imagenData: e.target.files[0],
                      imagenAvatar: URL.createObjectURL(e.target.files[0])
                    });
                    //setState({ ...state, imagenAvatar: URL.createObjectURL(e.target.files[0]) });
                  }} />
                <Avatar style={{ marginRight: '10px' }}>
                  <IconButton onClick={() => { hiddenFileInput.current.click(); }} >
                    <PhotoCameraIcon />
                  </IconButton>
                </Avatar>

                <Box display='flex' justifyContent='center' width={1}>
                  <img
                    alt={state.nombre}
                    src={state.imagenAvatar}
                    style={{ margin: '10px', maxWidth: '100%', maxHeight: '200px' }}
                  />
                </Box>

              </Box>

              <Container maxWidth='xl'>
                <Box>

                  <TextField
                    margin='dense'
                    size='small'
                    variant='standard'
                    fullWidth
                    id="nombre"
                    label='Texto abreviado'
                    name='nombre'
                    autoComplete='nombre'

                    value={state.nombre}
                    onChange={handleInputChange}
                  />

                  <FormControl fullWidth>
                    <InputLabel id="set-ubicacion">Ubicación</InputLabel>
                    <Select
                      labelId="set-ubicacion"
                      id="ubicacion"
                      value={state.ubicacion}
                      onChange={(e) => setState({ ...state, 'ubicacion': e.target.value })}
                    >
                      {
                        props.ubicaciones.map((element) => {
                          return <MenuItem value={element._id} key={element._id}>{element.nombre}</MenuItem>
                        })
                      }
                    </Select>
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel id="set-bot">Bot</InputLabel>
                    <Select
                      labelId="set-bot"
                      id="bot"
                      value={state.bot}
                      onChange={(e) => setState({ ...state, 'bot': e.target.value })}
                    >
                      {
                        props.bots.map((element) => {
                          return <MenuItem value={element._id} key={element._id}><BotMenuItem item={element} /></MenuItem>
                        })
                      }
                    </Select>
                  </FormControl>

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.ejecucionDirecta}
                        onChange={(e) => {
                          setState({ ...state, 'ejecucionDirecta': !state.ejecucionDirecta });
                        }}
                        name={"ejecucionDirecta"}
                        color="primary"
                      />
                    }
                    label="Ejecución directa"
                  />

                  <Box display='flex' justifyContent='space-around'>
                    <TranscursoTiempo titulo={'Momento Activación'} item={state.momentoActivacion} onChange={(element) => setState({ ...state, 'momentoActivacion': element })} />
                    <TranscursoTiempo titulo={'Duración Tarea'} item={state.duracionTarea} onChange={(element) => setState({ ...state, 'duracionTarea': element })} />
                  </Box>

                  <Box>

                    <Box display='flex'>

                      <TextField
                        margin='dense'
                        size='small'
                        variant='standard'
                        fullWidth
                        id="tag"
                        label='New Tag'
                        name='tag'

                        helperText={'3 carácteres mínimo'}

                        value={tag}
                        onChange={(e) => setTag(e.target.value)}

                        onKeyUp={(e) => {
                          if (e.key === 'Enter' && tag.length >= 3) {
                            addTag();
                          }
                        }}

                      />

                      <IconButton
                        disabled={tag.length < 3}
                        title={'añadir tag'}
                        onClick={addTag}                    >
                        <AddCircleIcon />
                      </IconButton>

                    </Box>


                    {
                      state.tags.map((element, index) => {
                        return <Chip
                          label={element}
                          size='small'
                          color="primary"
                          style={{ margin: '3px' }}
                          key={element + index}
                          onClick={() => { deleteTag(element) }}
                        />
                      })
                    }

                  </Box>

                </Box>
              </Container>

            </Box>
          )}

          {tabIndex === 1 && (
            <Box display='flex' flexDirection='column'>
              <Typography align="center" variant="button">{'Slides'}</Typography>
              {state.slides.map((element) => {
                return <SlideViewWorld item={element} setElementShow={() => setElementShow(element)} key={element.id} onDelete={() => deleteSlide(element.id)} />;
              })}
            </Box>
          )}

        </Box>

        <div style={{ height: 20 }}></div>

        <Box display='flex' justifyContent='flex-end' width={1}>

          {
            props.onDelete ?
              <Button
                variant="contained"
                color="secondary"
                style={{ margin: '5px' }}
                onClick={() => setOpenConfirmationDialog(true)}
              >
                Borrar
              </Button>
              : <div></div>
          }

          <Button
            variant="contained"
            color="primary"
            style={{ margin: '5px' }}
            onClick={() => saveSlide()}
          >
            ADD Slide
          </Button>

          <Button
            variant="contained"
            color="default"
            style={{ margin: '5px' }}
            onClick={() => props.cancel()}
          >
            Cancelar
          </Button>

          <Button
            variant="contained"
            color="primary"
            style={{ margin: '5px' }}
            onClick={() => props.save(state)}
          >
            Guardar
          </Button>
        </Box>

      </Box>

    </Container>

  );
};