import React from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

import List from '@material-ui/core/List';

import Header from '../../component/header';
import { setBoxTitulo, setLoading } from '../../component/utils';

import { Simulacion } from '../../entity/simulacion';

// vista para la simulacion (cabecera)
import { SimulacionViewWorld } from '../simulacion/simulacionViewWorld';

class MisPlantillas extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      data: []
    }


    this.onCargar = this.onCargar.bind(this);
  }

  onCargar() {

    this.setState({ loading: true });


    Simulacion.misPlantillas()
      .then(
        data => {
          if (data.records) {

            this.setState({ loading: false, data: data.records });
            console.log(data.records);
          }
        }
      );

  }


  componentDidMount() {
    this.onCargar();
  }

  render() {

    return (
      <div className="background">

        <Header title="Plantillas" history={this.props.history}></Header>

        <div style={{ height: 20 }}></div>

        <Container maxWidth="sm">

          <Box>

            <List>
              {this.state.data.map((element) => {
                return <SimulacionViewWorld item={element} setElementShow={() => {
                  window.location.href = "/editor/" + element._id;
                }} key={element.id} />;
              })}
            </List>

            {!this.state.loading && (!this.state.data || this.state.data.length === 0) ?
              setBoxTitulo("No tiene Plantillas") : ''
            }

            <div style={{ height: 20 }}></div>

            <Box display="flex" justifyContent="center">
              {this.state.loading ? setLoading() : ''}
            </Box>

          </Box>


        </Container>
      </div>
    );
  }
}

export default MisPlantillas;