import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';

import DeleteIcon from '@material-ui/icons/Delete';

export function SlideViewWorld(props) {

  return (

    <Box className='background-list-cards' m={1} >

      <Box display='flex' m={0}>

        {
          props.onDelete ?
            <IconButton onClick={() => { props.onDelete() }} >
              <DeleteIcon />
            </IconButton>
            : <div></div>
        }

        <Box display='flex' justifyContent='center' alignItems='center' onClick={() => { props.setElementShow(props.item) }}>
          <Box m={1}>
            <Avatar>{props.item.orden}</Avatar>
          </Box>

          <Box display='flex' justifyContent='center'>
            <Typography align="center" variant="caption">{props.item.descripcion}</Typography>
          </Box>
        </Box>

      </Box>

    </Box>

  );
};