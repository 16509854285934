import React, { useState } from 'react';

import { Box, Avatar, IconButton } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import { RestaurantMenuRounded } from '@material-ui/icons';


export function BotAnimacionEstado(props) {

  const hiddenFileInput = React.createRef();

  const [url, setUrl] = useState(props.url);

  return (
    <Box textAlign='center'>
      <img
        alt={'estado' + props.index}
        src={url}
        style={{ margin: '10px', maxWidth: '100%', maxHeight: '200px' }}
      />

      <input ref={hiddenFileInput} accept='image/gif' type='file' style={{ display: 'none' }}
        onChange={e => {
          // si ino selecciono nada, no continuo
          if (e.target.files.length === 0) {
            return;
          }

          setUrl(URL.createObjectURL(e.target.files[0]));
          props.onClick(e.target.files[0]);
        }} />
      <Avatar style={{ marginRight: '10px' }}>
        <IconButton onClick={() => { hiddenFileInput.current.click(); }} >
          <PhotoCameraIcon />
        </IconButton>
      </Avatar>
    </Box>);
}