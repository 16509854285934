import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import { Container, IconButton, TextField } from '@material-ui/core';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';


export function SlideViewWorldEdit(props) {

  // inicializamos el hook con el estado
  const [state, setState] = useState(props.item);

  // ref para el input de tipo file
  const hiddenFileInput = React.createRef();

  // función que controla los cambios en los textfield
  const handleInputChange = e => {
    const { name, value } = e.target
    setState({ ...state, [name]: value })
  }

  // comprobamos si hemos cambiado de registro para mostrar
  useEffect(() => {
    setState(props.item);
  }, [props.item]);

  useEffect(() => {
  }, [state.imagenAvatar]);


  return (
    <Container maxWidth='md'>
      <Box className='background-forms'>

        <Box>

          <Box>
            <Box m={1}>
              <Box display='flex' justifyContent="center">
                <img
                  alt={state.descripcion.substring(0, 50) + '...'}
                  src={state.imagenAvatar}
                  style={{ margin: '10px', maxWidth: '100%', maxHeight: '200px' }}
                />
              </Box>

              <Box display='flex'>

                <input ref={hiddenFileInput} accept='image/*' type='file' style={{ display: 'none' }}
                  onChange={e => {
                    setState({
                      ...state,
                      imagenData: e.target.files[0],
                      imagenAvatar: URL.createObjectURL(e.target.files[0])
                    });
                  }} />
                <Avatar style={{ marginRight: '10px' }}>
                  <IconButton onClick={() => { hiddenFileInput.current.click(); }} >
                    <PhotoCameraIcon />
                  </IconButton>
                </Avatar>

                <TextField
                  margin='dense'
                  size='small'
                  variant='standard'
                  id="orden"
                  label='Orden'
                  name='orden'
                  type='number'

                  value={state.orden}
                  onChange={handleInputChange}
                />

              </Box>
            </Box>

            <TextField
              margin='dense'
              size='small'
              variant='standard'
              fullWidth
              id="descripcion"
              label='Texto a mostrar'
              name='descripcion'
              autoComplete='descripcion'

              multiline
              rows={10}

              value={state.nombre}
              onChange={handleInputChange}
            />
          </Box>

          <div style={{ height: 20 }}></div>

          <Box display='flex' justifyContent='flex-end' width={1}>

            {
              props.onDelete ?
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ margin: '5px' }}
                  onClick={() => props.onDelete(state)}
                >
                  Borrar
          </Button>
                : <div></div>
            }

            <Button
              variant="contained"
              color="default"
              style={{ margin: '5px' }}
              onClick={() => props.cancel()}
            >
              Cancelar
          </Button>

            <Button
              variant="contained"
              color="primary"
              style={{ margin: '5px' }}
              onClick={() => props.save(state)}
            >
              Guardar
          </Button>
          </Box>

        </Box>

      </Box>

    </Container>

  );
};