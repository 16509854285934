import React, { useState } from 'react';

import { Box, TextField } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

import { List, ListItem, ListItemText, ListItemAvatar, Paper } from '@material-ui/core';

export function ContainerSkills(props) {

  const [filtro, setFiltro] = useState('');

  return (
    <Box className='background-list-cards' m={1} p={1} width={400}>
      <Typography align="center" variant="body1">{'Skills disponibles'}</Typography>

      <TextField
        margin='dense'
        size='small'
        variant='standard'
        fullWidth
        label='Filtro'

        value={filtro}
        onChange={(e) => setFiltro(e.target.value)}
      />

      <Paper style={{ maxHeight: 300, overflow: 'auto' }}>
        <List>

          {
            props.skills && props.skills.length > 0
              ? (
                props.skills.filter(x => x.nombre.toLowerCase().indexOf(filtro.toLowerCase()) >= 0).map((element) => {
                  return <ListItem divider button onClick={() => props.addSkill(element)} key={(new Date()).getTime() + element.id}>
                    <ListItemAvatar>
                      <Avatar
                        src={element.imagenAvatar}
                        alt={element.nombre}
                        title={element.nombre} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={element.nombre} />
                  </ListItem>
                }
                )
              )
              : ''
          }

        </List>
      </Paper>

    </Box>
  );
};