import io from 'socket.io-client';
import { Constantes } from '../util/constantes';

const socketIO = io(Constantes.SOCKET_URI, {
  transports: ['websocket']
});


socketIO.on('connect', () => {
  if (Constantes.getUserLogged()) {
    socketIO.emit('playerOnline', Constantes.getUserLogged().token);
  }

});

socketIO.on('disconnect', () => {
  socketIO.emit('playerOffline');
});


export const socketCustom = socketIO;