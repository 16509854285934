import React from 'react';


import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';

import { Usuarios } from '../../entity/usuarios';

class Registro extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      nombre: '',
      email: '',
      usuario: '',
      clave: '',
      clave2: '',
      errorLogin: false,
      errorText: ''
    }


    this.onRegistro = this.onRegistro.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
  }

  onRegistro(event) {
    event.preventDefault();

    this.setState({ loading: true, errorLogin: false });

    // la clave y repetición de clave deben ser iguales
    if (this.state.clave !== this.state.clave2) {
      this.setState({ errorText: "Indique claves iguales", errorLogin: true, loading: false });
      return;
    }

    let formulario = {
      nombre: this.state.nombre,
      email: this.state.email,
      usuario: this.state.usuario,
      clave: this.state.clave,
      clave2: this.state.clave2
    }

    Usuarios.registro(formulario)
      .then(
        user => {
          if (user === true) {
            const { from } = this.props.location.state || { from: { pathname: "/login" } };
            this.props.history.push(from);
          }
          else {
            this.setState({ errorText: user.message, errorLogin: true, loading: false });
          }
        }
      );

  }

  onChangeInput(event) {
    this.setState({ [event.target.id]: event.target.value });
  }

  render() {
    const AlertloginFailed = <Alert severity="error">{this.state.errorText}</Alert>;

    return (
      <div>
        <div className="screenLogin"></div>

        <div style={{ height: 80 }}></div>

        <Container maxWidth="xs">

          <img src="./images/block/groobi_logo.png" width="100%" alt="Groobi" />

          <div style={{ height: 20 }}></div>

          <Box>

            <form noValidate autoComplete="off">

              <TextField
                margin="normal"
                required
                fullWidth
                id="nombre"
                label="Nombre"
                name="nombre"
                autoComplete="nombre"
                autoFocus

                onChange={this.onChangeInput}
              />

              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"

                onChange={this.onChangeInput}
              />

              <TextField
                margin="normal"
                required
                fullWidth
                id="usuario"
                label="Usuario"
                name="usuario"
                autoComplete="usuario"

                onChange={this.onChangeInput}
              />

              <TextField
                margin="normal"
                required
                fullWidth
                name="clave"
                label="Clave"
                type="password"
                id="clave"
                onChange={this.onChangeInput}
              />

              <TextField
                margin="normal"
                required
                fullWidth
                name="clave2"
                label="Repetir Clave"
                type="password"
                id="clave2"
                onChange={this.onChangeInput}
              />

              <div style={{ height: 20 }}></div>

              <Button
                fullWidth
                variant="contained"
                disabled={this.state.loading}
                color="primary"
                onClick={this.onRegistro}
              >
                Registrar
          </Button>

            </form>

            {this.state.errorLogin === true ? AlertloginFailed : ''}

          </Box>


        </Container>
      </div>
    );
  }
}

export default Registro;