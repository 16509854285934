import React, { useEffect, useState } from 'react';

import { Box, Collapse } from '@material-ui/core';
import { Button, IconButton } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';

import { Container, TextField } from '@material-ui/core';

import { Avatar, LinearProgress, Slider } from '@material-ui/core';


// utilidades
import { ConfirmDialog } from '../../component/utils';

import { ContainerSkills } from '../../component/containerSkills';


function UnaSituacion(props) {

  const [state, setState] = useState(props.item);

  const [showConfiguracion, setShowConfiguracion] = useState(false);

  // función que controla los cambios en los textfield
  const handleInputChange = e => {
    const { name, value } = e.target
    setState({ ...state, [name]: value })
  }


  const RequisitosSkills =
    state.requisitos != undefined && state.requisitos.length > 0
      ? (
        <Box className='background-list-cards' m={1} >
          <Typography align="center">{'Requisitos definidos'}</Typography>
          {
            state.requisitos.map((element, index) => {
              return <Box m={1} key={index} display='flex' alignItems='center'>

                <Avatar
                  src={element.imagenAvatar}
                  alt={element.nombre}
                  title={element.nombre} />

                <TextField
                  size='small'
                  variant='standard'
                  fullWidth
                  id="nivel"
                  label={element.nombre}
                  name='nivel'
                  type='number'
                  style={{ margin: 10 }}

                  value={element.valor}
                  onChange={(event) => {
                    let tmpSkill = state.requisitos;
                    tmpSkill[index].valor = event.target.value;
                    setState({ ...state, 'requisitos': tmpSkill });
                  }}
                />


                <IconButton onClick={() => {
                  let tmpSkill = state.requisitos;
                  tmpSkill.splice(index, 1);
                  setState({ ...state, 'requisitos': tmpSkill });

                }} >
                  <DeleteIcon />
                </IconButton>

              </Box>
            }
            )
          }
        </Box>
      )
      : <h3>No hay requisitos definidos</h3>;

  const ObjetivosSkills =
    state.objetivos != undefined && state.objetivos.length > 0
      ? (
        <Box className='background-list-cards' m={1} >
          <Typography align="center">{'Objetivos definidos'}</Typography>
          {
            state.objetivos.map((element, index) => {
              return <Box m={1} key={index} display='flex' alignItems='center'>

                <Avatar
                  src={element.imagenAvatar}
                  alt={element.nombre}
                  title={element.nombre} />

                <TextField
                  size='small'
                  variant='standard'
                  fullWidth
                  id="nivel"
                  label={element.nombre}
                  name='nivel'
                  type='number'
                  style={{ margin: 10 }}

                  value={element.valor}
                  onChange={(event) => {
                    let tmpSkill = state.objetivos;
                    tmpSkill[index].valor = event.target.value;
                  }}
                />

                <IconButton onClick={() => {
                  let tmpSkill = state.objetivos;
                  tmpSkill.splice(index, 1);
                  setState({ ...state, 'objetivos': tmpSkill });

                }} >
                  <DeleteIcon />
                </IconButton>

              </Box>
            }
            )
          }
        </Box>
      )
      : <h3>No hay objetivos definidos</h3>;

  return <Box className='background-forms'>

    <Box display='flex'>
      <TextField
        margin='dense'
        size='small'
        variant='standard'
        id="orden"
        label='Orden'
        name='orden'

        value={state.orden}
        onChange={handleInputChange}
      />

      <TextField
        margin='dense'
        size='small'
        variant='standard'
        id="nombre"
        label='Descripción'
        name='nombre'
        fullWidth

        value={state.nombre}
        onChange={handleInputChange}
      />


    </Box>

    <Box display='flex'>

      <IconButton onClick={() => setShowConfiguracion(!showConfiguracion)} >
        {showConfiguracion ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </IconButton>

      <Button
        variant="contained"
        color="primary"
        size='small'
        style={{ margin: '5px' }}
        onClick={() => props.onSave(state)}
      >
        Guardar
      </Button>

      <Button
        variant="contained"
        color="primary"
        size='small'
        style={{ margin: '5px' }}
        onClick={() => props.onBorrar(state)}
      >
        Borrar
      </Button>

    </Box>

    <Collapse in={showConfiguracion}>
      <Box display='flex' justifyContent='space-around'>
        <Box display='flex' justifyContent='center'>
          {RequisitosSkills}

          <ContainerSkills skills={props.skills} addSkill={(element) => {

            let tmp = { ...state };

            if (!tmp.requisitos) {
              tmp.requisitos = [];
            }

            // si el skill ya está añadido, no lo vuelvo a añadir
            let existe = tmp.requisitos.filter(x => x.id === element.id).length;

            // si no existe, lo añado
            if (existe === 0) {
              tmp.requisitos.push(element);
            }

            setState(tmp);
          }} />
        </Box>

        <Box display='flex' justifyContent='center'>
          {ObjetivosSkills}

          <ContainerSkills skills={props.skills} addSkill={(element) => {

            let tmp = { ...state };

            if (!tmp.objetivos) {
              tmp.objetivos = [];
            }

            // si el skill ya está añadido, no lo vuelvo a añadir
            let existe = tmp.objetivos.filter(x => x.id === element.id).length;

            // si no existe, lo añado
            if (existe === 0) {
              tmp.objetivos.push(element);
            }

            setState(tmp);
          }} />

        </Box>
      </Box>
    </Collapse>

  </Box>
}


export function SituacionesViewWorldEdit(props) {

  // inicializamos el hook con el estado
  const [state, setState] = useState(props.situaciones);

  useEffect(() => {
    if (state === undefined) setState([]);
  }, []);



  return (
    <Container maxWidth='lg'>

      <Box>

        <Box display='flex' alignItems='center'>

          <Typography>{'Añadir Situación'}</Typography>

          <IconButton onClick={() => {
            let tmp = [...state];

            tmp.push({ orden: 0, nombre: 'Nueva', requisitos: [], objetivos: [] });
            setState(tmp);

            if (props.onSave) props.onSave(tmp);
          }} >
            <AddCircleOutlineIcon />
          </IconButton>
        </Box>

        {
          state && state.map((element, index) => {
            return <UnaSituacion item={element} skills={props.skills}
              onSave={(situacion) => {
                let tmp = [...state];

                tmp[index] = situacion;

                setState(tmp);

                if (props.onSave) props.onSave(tmp);

              }}
              onBorrar={(situacion) => {

                let asignadas = props.tareasDeSeccion.filter(x => x.situacion === situacion.nombre).length;

                if (asignadas > 0) {
                  alert(`Esta situación tiene ${asignadas} tareas asignadas. No se puede borrar.`);
                }
                else {
                  let tmp = [...state];

                  tmp = tmp.filter(x => x.nombre !== situacion.nombre);

                  setState(tmp);

                  if (props.onSave) props.onSave(tmp);
                }
              }} />;
          })
        }

      </Box>

    </Container>

  );
};